// Generated by Framer (6f76210)

import { addFonts, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {mfNpwd34Q: {hover: true}};

const serializationHash = "framer-v0bcJ"

const variantClassNames = {mfNpwd34Q: "framer-v-rsv4yd"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "mfNpwd34Q", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-rsv4yd", className, classNames)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"mfNpwd34Q"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-4ae06ec5-e63c-481f-a2c6-fa69afaeeac5, rgb(255, 250, 68))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-4ae06ec5-e63c-481f-a2c6-fa69afaeeac5, rgb(237, 90, 17))", ...style}} variants={{"mfNpwd34Q-hover": {backgroundColor: "rgba(0, 0, 0, 0)"}}} {...addPropertyOverrides({"mfNpwd34Q-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SGVsdmV0aWNhTmV1ZS1Cb2xk", "--framer-font-family": "\"HelveticaNeue-Bold\", sans-serif"}}>CONTATO</motion.p></React.Fragment>} className={"framer-1yf2qqw"} data-framer-name={"Get a Quote"} fonts={["HelveticaNeue-Bold"]} layoutDependency={layoutDependency} layoutId={"BFOMbLu5G"} style={{"--framer-paragraph-spacing": "0px"}} variants={{"mfNpwd34Q-hover": {"--extracted-r6o4lv": "rgb(255, 255, 255)"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({"mfNpwd34Q-hover": {children: <React.Fragment><motion.p style={{"--font-selector": "SGVsdmV0aWNhTmV1ZS1Cb2xk", "--framer-font-family": "\"HelveticaNeue-Bold\", sans-serif", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>CONTATO</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-v0bcJ.framer-pinv43, .framer-v0bcJ .framer-pinv43 { display: block; }", ".framer-v0bcJ.framer-rsv4yd { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 12px 24px 12px 24px; position: relative; width: min-content; }", ".framer-v0bcJ .framer-1yf2qqw { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-v0bcJ.framer-rsv4yd { gap: 0px; } .framer-v0bcJ.framer-rsv4yd > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-v0bcJ.framer-rsv4yd > :first-child { margin-left: 0px; } .framer-v0bcJ.framer-rsv4yd > :last-child { margin-right: 0px; } }", ".framer-v0bcJ[data-border=\"true\"]::after, .framer-v0bcJ [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 43
 * @framerIntrinsicWidth 124
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"ZHSZOcS7k":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerXdu_xuXbB: React.ComponentType<Props> = withCSS(Component, css, "framer-v0bcJ") as typeof Component;
export default FramerXdu_xuXbB;

FramerXdu_xuXbB.displayName = "button-achar-meu-lar";

FramerXdu_xuXbB.defaultProps = {height: 43, width: 124};

addFonts(FramerXdu_xuXbB, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})